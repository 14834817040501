<template>
    <div>
        <b-nav-item>
            <b-button
                variant="flat-primary"
                class="btn-icon rounded-circle"
                @click="showModal = true"
                v-b-tooltip.hover
                title="AI大模型"
            >
                <b-icon-chat-left-dots-fill></b-icon-chat-left-dots-fill>
            </b-button>
        </b-nav-item>

        <b-modal
            id="chat-gpt-modal"
            v-model="showModal"
            title="AI大模型"
            hide-footer
            centered
            size="lg"
        >
            <div style="height: 80vh">
                <b-embed
                    :src="aiLink"
                    style="width: 100%; height: 100%; border: none"
                ></b-embed>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { computed, ref } from "@vue/composition-api";

export default {
    components: {},
    setup() {
        const aiLink = computed(() => {
            const settings = {
                url: "https://api.nextapi.fun",
                key: "ak-eoZJIc1gPDzhYKEmQynNx2G9Ti5PbiTlYoAaE9qwQU8AxaNd",
            };
            // https://app.nextchat.dev
            return (
                "https://a.nextapi.fun/#/?settings=" + JSON.stringify(settings)
            );
        });
        const showModal = ref(false);
        return {
            aiLink,
            showModal,
        };
    },
};
</script>

<style scoped lang="scss">
::v-deep .modal-lg {
    .modal-body {
        padding: 0 !important;
    }
}
</style>
