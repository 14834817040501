<template>
  <div style="height: 100%">
    <h3 class="font-title mt-2 px-2" v-if="hoverMenu[0]">
      {{ $t(hoverMenu[0].title) }}
    </h3>
    <div
      v-if="hoverMenu[0]"
      class="position-relative px-2 py-0 overflow-y-scroll d-flex"
      style="height: 100%; gap: 20px"
    >
      <!-- <div > -->
      <div
        v-for="(lvl_1, lvl_1_index) in hoverMenu"
        :key="lvl_1_index"
        class="d-flex flex-wrap justify-content-start w-100 active"
      >
        <template v-if="lvl_1.children && lvl_1.children.length > 0">
          <div
            class="d-flex flex-wrap justify-content-start w-100 mb-1"
            style="break-inside: avoid"
          >
            <template v-for="(lvl_2, lvl_2_index) in lvl_1.children">
              <!-- 若没有3级菜单，则直接显示磁贴 -->
              <div
                v-if="!lvl_2.children"
                :key="'lvl_2' + lvl_2_index"
                :class="hoverMenu[0].menu_id ? 'box' : ''"
                class="d-flex flex-column"
                style="padding-bottom: 6px"
              >
                <!-- {{ lvl_2 }} -->
                <tile-item :menu-item="lvl_2"></tile-item>
              </div>
              <!-- 若有3级菜单，则显示2级标题和磁贴 -->
              <div v-else :key="'lvl_2' + lvl_2_index" style="width: 180px">
                <h5 class="font-title my-1">{{ $t(lvl_2.title) }}</h5>
                <div style="break-inside: avoid">
                  <div
                    v-for="(lvl_3, lvl_3_index) in lvl_2.children"
                    :key="'lvl_3' + lvl_3_index"
                    class="d-flex flex-column"
                    style="padding-bottom: 6px; break-inside: avoid"
                  >
                    <general
                      v-if="[72].includes(project_id)"
                      :menu-item="lvl_3"
                    ></general>
                    {{ lvl_3.title }}
                    <!-- <tile-item v-else :menu-item="lvl_3"></tile-item> -->
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from "@vue/composition-api";
import { $themeConfig } from "@themeConfig";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import useAppConfig from "@core/app-config/useAppConfig";
import Ripple from "vue-ripple-directive";
import { routerParams } from "@/libs/utils/routerParams";
import store from "@/store";
import TileItem from "@/views/layout/app-sidebar-menu/components/TileItem";
import General from "@/views/layout/app-sidebar-menu/components/General";

export default {
  name: "VerticalMenu",
  directives: {
    Ripple,
  },
  props: [
    "menuTree",
    "mode"
  ],
  components: {
    VuePerfectScrollbar,
    TileItem,
    General,
  },
  setup(props) {
    /**
     * 优先读取store中的菜单列表
     * 张强 2021-08-09
     */
    const event = reactive({
      show: false,
      project_id: computed(() => {
        let project_id = routerParams("project_id");
        return parseInt(project_id);
      }),
      hoverMenu: computed(() => {
        let menu = JSON.parse(JSON.stringify(props.menuTree));
        let menuId = store.state.menus.hover.lvl_1 || -99;
        console.log("菜单", menu);
        return menu.filter((menuItem) => menuItem.menu_id === menuId);
      }),
      widthHandle: computed(() => {
        let result = {
          width: 450,
          column: 1,
          cells: 3,
        };
        // let length = event.hoverMenu.children?event.hoverMenu.children.length:0
        // 纵览时加宽
        if ([null, -99].includes(store.state.menus.hover.lvl_1)) {
          result = {
            width: 900,
            column: 2,
            cells: 3,
          };
          if (store.state.menus.list.length < 20) {
            result = {
              width: 450,
              column: 1,
              cells: 3,
            };
          }
          //锦什坊项目菜单尺寸
          if ([72].includes(event.project_id)) {
            result = {
              width: 900,
              column: 2,
              cells: 3,
            };
          }
          //海境界二期项目菜单尺寸
          if ([74].includes(event.project_id)) {
            result = {
              width: 900,
              column: 1,
              cells: 6,
            };
          }
          // 中国建筑院
          if ([7].includes(event.project_id)) {
            result = {
              width: 500,
              column: 2,
              cells: 2,
            };
          }
        }
        return result;
      }),
    });

    const { skin } = useAppConfig();

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    onMounted(() => {
      console.log("传过来的菜单数据", props.menuTree);
    });

    return {
      ...toRefs(event),
      // Skin
      skin,
      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
