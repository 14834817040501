<template>
    <b-modal id="all-apps-modal" size="lg" title="全部应用" hide-footer>
        <p class="app">数据质量</p>
        <b-row>
            <b-col cols="2">
                <b-button variant="flat-primary">
                    <h1><b-icon-search /></h1>
                    <label class="mb-0 text-truncate">风险扫描</label>
                </b-button>
            </b-col>

            <b-col cols="2">
                <b-button variant="flat-primary">
                    <h1><b-icon-lightning-fill /></h1>
                    <label class="mb-0 text-truncate">能源管理</label>
                </b-button>
            </b-col>

            <b-col cols="2">
                <b-button variant="flat-primary">
                    <h1><b-icon-thermometer-half /></h1>
                    <label class="mb-0 text-truncate">环境监测</label>
                </b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import { computed, reactive, toRefs } from "@vue/composition-api";
import { BNavItem } from "bootstrap-vue";
import { routerParams } from "@/libs/utils/routerParams";

export default {
    name: "AllApps",
    components: {
        BNavItem,
    },
    setup() {
        const { skin } = useAppConfig();

        const isDark = computed(() => skin.value === "dark");
        const event = reactive({
            app_store_modal: {
                show: false,
            },
            project_id: computed(() => {
                return parseInt(routerParams("project_id"));
            }),
            apps: [
                {
                    title: "中国近零能耗建筑在线案例集",
                    subtitle: "中国建筑科学研究院",
                    category: "系统",
                    status: "上线",
                    image: "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/app-store/cqc.png",
                    description: "中国建筑科学研究院中国近零能耗建筑在线案例集",
                    src: "http://www.nzebs.cn/",
                },
                {
                    title: "负荷预测",
                    subtitle: "中国建筑科学研究院",
                    category: "算法",
                    status: "上线",
                    image: "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/app-store/cqc.png",
                    description: "中国建筑科学研究院负荷预测",
                    src: "",
                },
                {
                    title: "健康建筑可持续运行认证应用系统",
                    subtitle: "中国质量认证中心",
                    category: "系统",
                    status: "上线",
                    image: "/images/features/cqc.jpg",
                    description:
                        "《健康建筑可持续评价技术规范》线上管理系统，为中国质量认证中心、参与认证的建筑企业、检测机构、评审专家提供一个线上申请、线上评审、持续监测的互联网平台。",
                    src: "http://cqc.mtec.cc/",
                },
                {
                    title: "监测计分及赛事管理系统",
                    subtitle: "中国国际太阳能十项全能竞赛",
                    category: "系统",
                    status: "下架",
                    image: "/images/features/sdc.jpg",
                    description:
                        "中国国际太阳能十项全能竞赛官方计分系统，对参赛作品能源、环境等竞赛数据进行实时监测和统计并实时计算和发布得分和排名。",
                    src: "",
                },
                {
                    title: "多联机节能量检测",
                    subtitle: "美的集团股份有限公司",
                    category: "算法",
                    status: "上线",
                    image: "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/app-store/cqc.png",
                    description: "Midea多联机节能量检测",
                    src: "",
                },
            ],
        });

        return {
            skin,
            isDark,
            ...toRefs(event),
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
